
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        











































































































































































































































.campaign-affiliate-reporting {
  @include page-layout;

  .filter-options {
    @media (min-width: $--sm) {
      max-width: 100%;

      .dropdown-cont {
        width: 75%;
        justify-content: flex-start;
      }

      .subid-report-controls {
        width: 25%;
        transform: translateY(18px);
      }

      .dropdown-item {
        width: 45%;
      }
    }

    @media (min-width: 1300px) {
      .dropdown-cont {
        width: 60%;
      }

      .subid-report-controls {
        width: 40%;
        flex-direction: row-reverse !important;
        align-items: baseline;
        transform: translateY(12px);
      }
    }
  }

  .affiliate-reporting-table {
    .el-table__body-wrapper {
      td.table-column-link {
        .cell {
          color: $--clb-color-primary;
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  .subid-report-filters {
    min-height: 126px;

    .el-input__inner {
      height: 36px !important;
    }
  }

  .date-picker-filter {
    transform: scale(0.9) translateX(-14px);

    @media (min-width: 330px) {
      transform: scale(1) translateX(0);
    }
  }
}
